export default {
    cartList:"Lista de Carrinho de Compras",
    editCart:"Edit Shopping Cart",
    continue:"Continuar",
    back:"retorno",
    finished:"Completo",
    total:"Total",
    deliveryAnTime:"Método e tempo de entrega",
    pickUp:"Auto- recuperação",
    pickUpRemark:"Vá ao restaurante e buscá-lo você mesmo",
    delivery:"Entrega de refeições",
    deliveryRemark:"Enviar para o seu endereço",
    buyerInfo:"Informação do Comprador",
    name:"Nome",
    telephone:"Telefone",
    eamil:"Correio",
    postNumber:"Número de código postal",
    postFeeTip:"Taxa de entrega das refeições ",
    postBy:"Endereço de código postal",
    address:"Endereço pormenorizado",
    adult:"Adulto",
    child:"Criança",
    special:"Observações especiais",
    subTotal:"Subtotal",
    nameNotNull:"Por favor indique o seu nome",
    phoneNotNull:"Introduza um número de telefone",
    mailNotNull:"Indique por favor um email",
    mailEror:"Erro no formato do e- mail",
    postnumberNotNull:"Indique o código postal",
    postByNotNull:"Indique um endereço postal",
    addresssNotNull:"Indique o endereço pormenorizado",
    voksneNotNull:"Indique por favor o número de pessoas",
    amountNotNull:"O valor do pedido é 0, envio não permitido",
    orderSuccess:"Apresentado com sucesso",
    requestError:"Solicite erro de endereço, verifique o endereço de acesso",
    sure:"Confirmar",
    error:"Erro",
    confirmOrder:"Confirmar a Ordem",
    submit:"Enviar",
    deliveryTime:"Prazo de entrega",
    ordreSuccess:"Colocar uma encomenda com sucesso",
    sendSuccess:"A encomenda foi enviada com sucesso!",
    sendSayTip:"Obrigado por encomendar a nossa comida. Ligaremos o mais rápido possível para confirmar a sua encomenda",
    orderDetial:"Ver ordens",
    home:"Página inicial",
    noOrder:"Nenhuma informação da ordem armazenada, verifique por favor após colocar a ordem",
    login:"Login",
    passwordLogin:"Senha de autenticação",
    smsLogin:"Login SMS",
    register:"Registo",
    forget:"Esqueceu- se da senha",
    send:"Enviar",
    videCode:"Código de verificação",
    passwordNotNull:"A senha não pode estar vazia",
    registered:"O utilizador registou-se, por favor faça o login",
    gotoOrder:"Ir para a ordem",
    emptyCartList:"O cesto está vazio. Em caso de dúvida, comece com um dos nossos saborosos menus",
    shopCart:"O seu carrinho de compras",
    shopName:"Nome do Prato",
    num:"Quantidade",
    delete:"Apagar",
    price:"Preço unitário",
    coupon:"Cupão",
    phoneLenLimit:"O número de telefone deve ter 8 dígitos",
    timeNotNull:"Seleccione por favor a hora da encomenda",
    restaurant:"Restaurante",
    leveringOrAfhentning:"Entrega/Recolha automática",
    gotoBox:"Vá para a caixa",
    cart:"Carrinho de compras",
    pendingOrderInfo:"Afventende ordreinformation",
    orderingInfo:"Informação da Ordem",
    noRecentOrders:"Não existem encomendas recentes",
    choice:"Seleccionado",
    close:"Fechar",
    restaurantName:"Restaurante",
    yourName:"Dit navn",
    numberDiners:"Número de clientes",
    bookTime:"Hora programada",
    bestilling:"Reserva",
    nullStore:"Não existe informação de armazenamento. Se o nome de domínio não estiver vinculado a um armazenamento, por favor vá para a infra-estrutura para vinculá-lo",
    bookTimeNull:"Selecione a hora completa da reserva", 
    selectHours:"Seleccionar horas",
    selectMinutes:"Seleccionar minutos",
    informationNull:"Informações exigidas não totalmente preenchidas",
    successfullyBooked:"Reservado com sucesso",
    reservationFailed:"A reserva falhou",
    nullCustomer:"Este nome e número de telefone não existem, por favor verifique as informações!",
    unsubscribe:"Cancelar a subscrição da Newsletter",
    find:"Encontrar",
    os:"Nós",
    openingHours:"Horário de funcionamento",
    afmeld:"Confirm cancellation",
    paymentMethod:"Forma de pagamento",
    deliveryOptionTitle:"Por favor, escolha o método de entrega",
    needPaytype:"Seleccione por favor um método de pagamento",
    }
    