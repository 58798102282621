// 静态路由
export const staticRouter = [
    {
        path:'/',
        component:() => import('../views/Index.vue'),
        meta: { title: ''}
    },
    {
        path:'/:id',
        component:() => import('../views/Index.vue'),
        meta: { title: ''}
    },
    {
        path:'/takeaway/:id',
        component:() => import('../views/pages/IndexView.vue'),
        meta: { title: ''}
    },
    {
        path:'/takeaway/paymentSuccess/:id',
        component:() => import('../views/results/PaymentSuccess.vue'),
        meta: { title: ''}
    },
    {
        path:'/takeaway/paymentFailed/:id',
        component:() => import('../views/results/PaymentFailed.vue'),
        meta: { title: ''}
    },
    {
        path:'/takeaway/cartList/:id',
        component:() => import('../views/pages/CartListView.vue'),
        meta: { title: 'Delivery and time'}
    },
    {
        path:'/takeaway/buyerInfo/:id',
        component:() => import('../views/pages/BuyerInfoView.vue'),
        meta: { title: 'Delivery and time'}
    },
    {
        path:'/booking/:id',
        component:() => import('../views/pages/BookingTable.vue'),
        meta: { title: ''}
    },
    {
        path:'/page/:customId/:id',
        component:() => import('../views/pages/CustomView.vue'),
        meta: { title: ''}
    },
    //mobile
    {
        path:'/mobile/takeaway/:id',
        component:() => import('../views/mobile/IndexView.vue'),
        meta: { title: ''},
    },
    {
        name:'mobileFoodList',
        path:'/mobile/takeaway/foodlist/:id',
        component:() => import('../views/mobile/FoodListView.vue'),
        meta: { title: ''}
    },
   
    {
        path:'/mobile/takeaway/cartlist/:id',
        component:() => import('../views/mobile/CartListView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/takeaway/time/:id',
        component:() => import('../views/mobile/TimeView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/takeaway/buyerInfo/:id',
        component:() => import('../views/mobile/BuyerInfoView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/takeaway/success/:id',
        component:() => import('../views/mobile/OrderSuccessView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/takeaway/detail/:id',
        component:() => import('../views/mobile/OrderDetailsView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/booking/:id',
        component:() => import('../views/mobile/BookingTable.vue'),
        meta: { title: ''}
    },

    // {
    //     path:'*',
    //     component:() => import('../views/staticRoutes/static1.vue'),
    //     meta: { title: '错误404',hidden:true}
    // }
      //demo 
      {
        path:'/mobile/takeaway/:id/:foodTypeId',
        component:() => import('../views/mobile/IndexView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/takeaway/foodlist/:id/:foodTypeId/:foodTypeName/:showType',
        component:() => import('../views/mobile/FoodListView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/takeaway/time/:id/:deliveryTip',
        component:() => import('../views/mobile/TimeView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/takeaway/buyerInfo/:id/:deliveryTip',
        component:() => import('../views/mobile/BuyerInfoView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/page/:customId/:id',
        component:() => import('../views/mobile/CustomView.vue'),
        meta: { title: ''}
    },
    {
        path:'/b/takeaway/:id',
        component:() => import('../views/takeaway02/IndexView.vue'),
        meta: { title: ''}
    },
    {
        path:'/b/checkout/:id',
        component:() => import('../views/takeaway02/Checkout.vue'),
        meta: { title: ''}
    },
]
