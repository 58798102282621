<template>
  <div id="app">
    <div class="container">
      <router-view></router-view>
      <lg-preview></lg-preview>
    </div>
  </div>

</template>
 
<script>
import useaddRoute from "./utils/loadingRouter";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {},
  data() {
    return {
      
    };
  },
  created() {
    useaddRoute();
  },
  mounted() {
      var restaurantName=this.$route.params.id;
      
      //模拟演示特殊情况

       // 根据不同路由跳转不同页面
      if (restaurantName != undefined) {
      if (this.isMobile()) {
        this.$router.replace('/mobile/takeaway/' + restaurantName)
      } else {
        this.$router.replace('/takeaway/' + restaurantName)
      }
    }
  },
 
  computed: {
    ...mapGetters(["updateNav", "updateStore"]),
  },
 
  methods: {
     isMobile () {
      if (typeof window !== 'undefined' && window.innerWidth <= 768) {
                return true;
            } else {
                return false;
            }
    }
  },
};
</script>

<style lang="scss">

</style>