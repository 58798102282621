import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
 
import store from './store/index.js'
import global from './utils/global.js'
import i18n from './i18n'
import VueCountryIntl from 'vue-country-intl';
// import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
import * as VueGoogleMaps from 'vue2-google-maps';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import 'vue-country-intl/lib/vue-country-intl.css'
// import './assets/css/page.css'
// import './assets/css/public.css'
// import './assets/css/mobile.css'
// Vue.use(Antd);
Vue.use(ElementUI);
Vue.component(VueCountryIntl.name, VueCountryIntl);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCvH8jYv9ajpgRXeMB9ZdVQt8y_dtkn6Vo',
    libraries: 'places',
  },
});

let options = {
  maxSpreadZoom: 1, // 预览图最大的倍数，默认2倍
  fullscreenEl: false, //是否显示右上角全屏按钮
  closeEl: true, //是否显示右上角关闭按钮
  tapToClose: true, //点击滑动区域应关闭图库
  shareEl: false, //是否显示分享按钮
  zoomEl: true, //是否显示放大缩小按钮
  counterEl: false, //是否显示左上角图片数量按钮
  arrowEl: true,  //是否显示左右箭头（pc浏览器模拟手机时）
  tapToToggleControls: false, //点击应切换控件的可见性
  clickToCloseNonZoomable: true //点击图片应关闭图库，仅当图像小于视口的大小时
}
Vue.use(preview, options)

Vue.config.productionTip = false
Vue.prototype.global = global


new Vue({
  el:'#app',
  router,
  store,
  i18n,
  render: h => h(App),
})