export default {
cartList:"购物车列表",
editCart:"编辑购物车",
back:"返回",
continue:"下一步",
finished:"完成",
total:"总计",
deliveryAnTime:"配送方式和时间",
pickUp:"自取",
pickUpRemark:"自己到餐厅取",
delivery:"送餐",
deliveryRemark:"配送到您地址",
buyerInfo:"买方信息",
name:"名字",
telephone:"电话",
eamil:"邮件",
postNumber:"邮编号码",
postFeeTip:"送餐费 ",
postBy:"邮编地址",
address:"详细地址",
adult:"成人",
child:"小孩",
special:"特殊备注",
subTotal:"小计",
nameNotNull:"请输入姓名",
phoneNotNull:"请输入电话号码",
mailNotNull:"请输入邮件",
mailEror:"邮件格式错误",
postnumberNotNull:"请输入邮编",
postByNotNull:"请输入邮编地址",
addresssNotNull:"请输入详细地址",
voksneNotNull:"请输入人数",
amountNotNull:"订单金额为0，不允许提交",
orderSuccess:"成功下单",
requestError:"请求地址错误，请检查访问地址", 
sure:"确定",
error:"错误",
confirmOrder:"确认订单",
submit:"提交",
deliveryTime:"送达时间",
ordreSuccess:"成功下单",
sendSuccess:"订单已经发送成功!",
sendSayTip:"感谢您订购我们食物。我们会尽快给您打电话确认您的订单",
orderDetial:"查看订单",
home:"首页",
noOrder:"不存订单信息，请下单后查看",
login:"登录",
passwordLogin:"密码登录",
smsLogin:"短信登录",
register:"注册",
forget:"忘记密码",
send:"发送",
videCode:"验证码",
passwordNotNull:"密码不允许为空",
registered:"用户已注册，请登录就好",
gotoOrder:"转到订单",
emptyCartList:"你的购物车是空的。如果有疑问，请从我们的一份美味菜单开始",
shopCart:"您的购物车",
shopName:"菜品名称",
num:"数量",
delete:"删除",
price:"单价",
coupon:"优惠券",
phoneLenLimit:"号码必须为8位",
timeNotNull:"请选择订单时间",
restaurant:"餐厅",
leveringOrAfhentning:"送餐/自取",
gotoBox:"转到购物车",
cart:"购物车",
pendingOrderInfo:"待确定的订单信息",
orderingInfo:"订单信息",
noRecentOrders:"不存在最近订单",
choice:"已选择",
close:"关闭",
restaurantName:"餐厅",
yourName:"您的名称",
numberDiners:"就餐人数",
bookTime:"预定时间",
bestilling:"订座",
nullStore:"不存在店铺信息，若域名没绑定店铺，请到后台进行绑定！",
bookTimeNull:"请选择完整的预定时间", 
selectHours:"选择小时",
selectMinutes:"选择分钟",
informationNull:"未完整填写必填信息",
successfullyBooked:"预定成功",
reservationFailed:"预定失败",
nullCustomer:"不存在此名字和电话号码，请核对信息！",
unsubscribe:"取消订阅信息",
find:"找到",
os:"我们",
openingHours:"营业时间",
Wednesday:"星期三",
afmeld:"确定取消",
paymentMethod:"支付方式",
payment:"现在支付",
paymentRemark:"当您点击付款时，您将被发送到我们的付款页面，在那里您将完成付款.",
restaurantRemark:"到线下店内支付",
deliveryOptionTitle:"请选择送餐方式",
needPaytype:"请选择付款方式",
}
